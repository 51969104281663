import React from 'react';
import Model from './3DModel';
const { motion } = require("framer-motion");

function Unicorn3D(props) {
    const dynamics = props.dynamics;
    return (
        <motion.div style={{
            position: 'absolute',
            top: '0',
            height: '200vh',
            backgroundColor: 'white',
            opacity: dynamics['opacity'],
            scale: dynamics['scale'],
            ...props.style
        }}>
            <Model src='models/unicorn-grey.gltf' animationSpeed={0.04} style={{
                position: 'absolute',
                height: '200vh',
                backgroundColor: 'white'
            }} />
        </motion.div>
    );
}

export default Unicorn3D;