import React from 'react';
import { useTransform, useViewportScroll, motion } from "framer-motion"

const FRAME = .0001

export function useDynamicsOnScroll(props) {

    const defaults = {
        'preSetup': 0.0,
        'setup': 0.25,
        'hold': 0.5,
        'preFinish': 0.25,
        'parentSetup': 0.0,
        'parentPeriod': 1.0,
        'startScale': 1.0,
        'holdScale': 100.0,
        'stopScale': 1.0,
        'startOpacity': 1.0,
        'holdOpacity': 1.0,
        'stopOpacity': 1.0
    }

    const parameters = {
        ...defaults,
        ...props
    };

    var preSetup = parameters['preSetup']
    var setup = parameters['setup']
    var hold = parameters['hold']
    var preFinish = parameters['preFinish']
    var parentSetup = parameters['parentSetup']
    var parentPeriod = parameters['parentPeriod']
    var startScale = parameters['startScale']
    var holdScale = parameters['holdScale']
    var stopScale = parameters['stopScale']
    var startOpacity = parameters['startOpacity']
    var holdOpacity = parameters['holdOpacity']
    var stopOpacity = parameters['stopOpacity']
    const { scrollYProgress } = useViewportScroll();

    //  cycle: amount of time (percent of the parent lifecycle) that the component is active
    //  setup: amount of time (percent of the parent lifecycle) that the component spends animating
    //  into its held position.
    //  hold: amount of time (percent of the parent lifecycle) that the component remains
    //  held in position before animating out of frame.

    //  [preSetup] [setup] [hold] [preFinish]
    preSetup = preSetup * parentPeriod;
    preFinish = preFinish * parentPeriod;
    setup = setup * parentPeriod;
    hold = hold * parentPeriod;

    const scale = useTransform(scrollYProgress,
        [
            0.0,
            parentSetup,
            parentSetup + preSetup,
            parentSetup + preSetup + FRAME,
            parentSetup + preSetup + setup,
            parentSetup + preSetup + setup + hold,
            parentSetup + preSetup + setup + hold + preFinish,
            parentSetup + preSetup + setup + hold + preFinish + FRAME
        ],
        [
            0.0,
            parentSetup > 0 ? 0.0 : startScale,
            (parentSetup + preSetup) > 0 ? 0.0 : startScale,
            startScale,
            holdScale,
            holdScale,
            stopScale,
            0.0,
        ]
    );

    const opacity = useTransform(scrollYProgress,
        [
            0.0,
            parentSetup,
            parentSetup + preSetup,
            parentSetup + preSetup + FRAME,
            parentSetup + preSetup + setup,
            parentSetup + preSetup + setup + hold,
            parentSetup + preSetup + setup + hold + preFinish,
            parentSetup + preSetup + setup + hold + preFinish + FRAME
        ],
        [
            0.0,
            parentSetup > 0 ? 0.0 : startOpacity,
            (parentSetup + preSetup) > 0 ? 0.0 : startOpacity,
            startOpacity,
            holdOpacity,
            holdOpacity,
            stopOpacity,
            0.0,
        ]
    );

    return {
        scale: scale,
        opacity: opacity
    }
};

export default useDynamicsOnScroll;