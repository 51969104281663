import React from 'react';
import * as Shared from '../Shared';
import Video from './Video'
const { motion } = require("framer-motion");

function getWidth() {
    return '100vw';
}

function getHeight() {
    return '100vh';
}

function VideoExperience(props) {
    const dynamics = props.dynamics;
    const videoContainerStyle = {
        position: 'absolute',
        opacity: dynamics['opacity'],
        scale: dynamics['scale'],
        width: getWidth(),
        height: getHeight(),
        minHeight: '100vh',
        ...props.style
    };

    if (Shared.formFactor == Shared.FormFactors.GTE992PX_IPADPRO) {
        return (
            <motion.div style={videoContainerStyle}>
                <Video src='https://brandontory-http.s3.us-east-2.amazonaws.com/emotion-montage-mobile.mp4' style={{
                    position: 'absolute',
                    top: '0',
                    minWidth: '100%',
                    minHeight: '100%'
                }} />
            </motion.div>
        );
    } else if (Shared.formFactor == Shared.FormFactors.LTE991PX) {
        return (
            <motion.div style={videoContainerStyle}>
                <Video src='https://brandontory-http.s3.us-east-2.amazonaws.com/emotion-montage-web.mp4' style={{
                    position: 'absolute',
                    top: '0',
                    minWidth: '100%',
                    minHeight: '100%'
                }} />
            </motion.div>
        );
    } else if (Shared.formFactor == Shared.FormFactors.LTE767PX) {
        return (
            <motion.div style={videoContainerStyle}>
                <Video src='https://brandontory-http.s3.us-east-2.amazonaws.com/emotion-montage-mobile.mp4' style={{
                    position: 'absolute',
                    top: '0',
                    minWidth: '100%',
                    minHeight: '100%'
                }} />
            </motion.div>
        );
    } else if (Shared.formFactor == Shared.FormFactors.LTE479PX) {
        return (
            <motion.div style={videoContainerStyle}>
                <Video src='https://brandontory-http.s3.us-east-2.amazonaws.com/emotion-montage-mobile.mp4' style={{
                    position: 'absolute',
                    top: '0',
                    height: '100vh',
                    minHeight: '100%'
                }} />
            </motion.div>
        );
    } else {
        return (
            <motion.div style={videoContainerStyle}>
                <Video src='https://brandontory-http.s3.us-east-2.amazonaws.com/emotion-montage-web.mp4' style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '100vh',
                    minHeight: '100vh'
                }} />
            </motion.div>
        );
    }
}

export default VideoExperience;