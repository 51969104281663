import React, { useState } from 'react';
import * as Shared from '../Shared';
const { motion } = require("framer-motion");

var bannerOpacities = [
    1.0,
    1.0
]

function getWidth() {
    return '100vw';
}

function getHeight() {
    return '100vh';
}

function updateTimestamp() {
    const timestamp = new Date();
    const element = document.getElementById('timestamp');
    if (element) {
        element.innerText = `${timestamp.getHours()}:${timestamp.getMinutes()}:${timestamp.getSeconds()}:${('00' + timestamp.getMilliseconds()).slice(-3)}`;
    }
    setTimeout(updateTimestamp, 50);
}

function updateSongTitle() {
    const items = [
        'STREETS OF GOLD',
        'SEX DRUGS YOU',
        'LIFE I CHOSE',
        'COACHELLA',
        'GOAT',
        'UNICORNS',
        'TRIBE',
        'LIGHT IN THE DARK',
        'CELEBRITY',
        'DR!P',
        'BLACK TUXES',
        'JEALOUS',
        'DREAM'
    ];
    const itemIndex = Math.floor(Math.random() * items.length);
    const element = document.getElementById('songtitle');
    if (element) {
        element.innerText = `${items[itemIndex]}`;
    }
    setTimeout(updateSongTitle, 3000);
}

function updateMobileSongTitle() {
    const items = [
        ['STREETS', 'OF', 'GOLD'],
        ['SEX', 'DRUGS', 'YOU'],
        ['LIFE', 'I', 'CHOSE'],
        ['BLACK', 'TUXES', ''],
        ['BLACK', 'TUXES', ''],
        ['GOAT', '', ''],
        ['TRIBE', '', ''],
        ['DR!P', '', ''],
        ['DREAM', '', '']
    ];
    const itemIndex = Math.floor(Math.random() * items.length);
    const element1 = document.getElementById('partialSongTitle1');
    const element2 = document.getElementById('partialSongTitle2');
    const element3 = document.getElementById('partialSongTitle3');
    if (element1 && element2 && element3) {
        element1.innerText = `${items[itemIndex][0]}`;
        element2.innerText = `${items[itemIndex][1]}`;
        element3.innerText = `${items[itemIndex][2]}`;
    }
    setTimeout(updateMobileSongTitle, 3000);
}

function MusicText() {
    const textStyle = {
        fontFamily: "Helvetica Neue LT Std",
        fontSize: "9vh",
        marginTop: '0px',
        marginBottom: '0px',
        marginLeft: '2vw',
        paddingTop: '10vh',
        paddingBottom: '0px',
        height: '9vh',
        alignItems: 'center',
        display: 'flex'
    };

    if (Shared.formFactor == Shared.FormFactors.GTE992PX) {
        return (
            <div id="songtitle" ref={() => { updateSongTitle() }} class={'stroke'} style={{ ...textStyle, fontSize: '10vw', marginTop: '4vh' }}>STREETS OF GOLD</div>
        );
    } else {
        return (
            <div className={'stroke'}>
                <div id="partialSongTitle1" ref={() => { updateMobileSongTitle() }} style={{ ...textStyle, fontSize: "10vh" }} > LIFE</div>
                <div id="partialSongTitle2" style={{ ...textStyle, fontSize: "10vh" }} > I</div>
                <div id="partialSongTitle3" style={{ ...textStyle, fontSize: "10vh" }} > CHOSE</div >
            </div >);
    }
}

function UN7C0RN() {
    const [colorwayIndex, setColorwayIndex] = useState(0);

    if (Shared.formFactor == Shared.FormFactors.GTE992PX) {
        return (
            <motion.img src='FRONT_UN7C0RNAsset 6-black@3x.png' style={{
                position: 'relative',
                display: 'block',
                width: '95%',
                margin: 'auto',
                opacity: bannerOpacities[colorwayIndex]
            }} />);
    } else {
        return (
            <motion.img src='FRONT_UN7C0RNAsset 6-black@3x.png' style={{
                width: '90vw',
                marginLeft: '5vw',
                marginRight: '5vw',
                marginTop: '20vh',
                opacity: bannerOpacities[colorwayIndex]
            }} />);
    }
}

function Links() {
    const dateStyle = {
        color: 'black',
        // fontFamily: "Helvetica Neue LT Std",
        fontFamily: "VCR OSD Mono",
        fontSize: "1.5vh",
        fontWeight: '500',
        paddingTop: "3vh",
        paddingBottom: "3vh",
        textAlign: 'center',
        width: '100%'
    };

    const tableStyle = {
        color: 'black',
        fontFamily: Shared.brandH2Font,
        fontSize: "10vw",
        width: '100%'
    }

    if (Shared.formFactor == Shared.FormFactors.GTE992PX) {
        tableStyle['fontSize'] = '5vw';
    } else if (Shared.formFactor == Shared.FormFactors.LTE479PX) {
        tableStyle['fontSize'] = '12vw';
    }

    const tableDataStyle = {
        border: '1px solid black',
        borderLeft: 'none',
        borderRight: 'none',
        width: '100%',
        paddingTop: "1vh",
        paddingBottom: "1vh",
        paddingLeft: '2vw',
        width: '30%',
        textAlign: 'center'
    }

    const tableDataRightBorderStyle = {
        ...tableDataStyle,
        borderRight: '1px solid black'
    }
    return (
        <div style={{
            position: 'relative',
            height: '100vh',
            backgroundColor: '#ffffff',
        }}>
            <div id="timestamp" ref={() => {
                updateTimestamp()
            }} style={dateStyle}>
            </div>
            <table style={tableStyle}>
                <tr>
                    <td style={tableDataRightBorderStyle}><a className={'stroke-on-hover'} href="https://hypeddit.com/link/m6pwyh">STREAM</a></td>
                    <td style={tableDataStyle}><a className={'stroke-on-hover'} href="/NFT">NFT</a></td>
                </tr>
                <tr>
                    <td style={tableDataRightBorderStyle}><a className={'stroke-on-hover'} href="https://my.community.com/brandontory">CONNECT</a></td>
                    <td style={tableDataStyle}><a className={'stroke-on-hover'} href="https://instagram.com/brandontory">FOLLOW</a></td>
                </tr>
            </table>
            {/* <MusicText /> */}
            <UN7C0RN />
        </div >
    );
}

export default Links;
