import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts/Linotype - HelveticaNeueLTStd-Md.ttf';
import * as Shared from './Shared';
import { getFormFactor, getViewport } from './Shared'

var _APP_IS_MOUNTED = false;

function ReactRender(element) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    element
  );
  _APP_IS_MOUNTED = true;
}

function unmountAndPrintWarning(element, warning) {
  ReactDOM.unmountComponentAtNode(element);
  element.innerHTML = `<h2 class='app-warn-h1'>${warning}</h2>
  <h2 class='app-warn-link-h2'><a class='black-link' href="https://www.youtube.com/watch?v=1aVf82CIP_U">YOUTUBE</a></h2>
  <h2 class='app-warn-link-h2'><a class='black-link' href="https://soundcloud.com/brandontory/streets-of-gold-un7c0rn">SOUNDCLOUD</a></h2>`
  _APP_IS_MOUNTED = false;
}

function appIsInPortraitMode() {
  console.log(`orientation: ${window.orientation}`);
  return window.orientation == 0 || window.orientation === undefined;
}

function appIsIn900x600Mode() {
  if (Shared.viewportHeight == 600 && Shared.viewportWidth == 900) {
    console.log(`900x600 mode.`);
    return true;
  } else {
    return false;
  }
}

function MaybeReactRender() {
  var viewport = getViewport();
  var root = document.getElementById('root')
  var mql = window.matchMedia("(orientation: portrait)");

  if (appIsIn900x600Mode) {
    if (!_APP_IS_MOUNTED) {
      ReactRender(root);
    }
  } else if (!appIsInPortraitMode()) {
    unmountAndPrintWarning(root, 'PLEASE HOLD DEVICE VERTICALLY TO VIEW FULL EXPERIENCE.<br><br>请垂直保持设备以查看此经验。<br><br>אנא החזיקו את המכשיר בצורה Vertical כדי לראות את החוויה הזו.');
  }
  else if (viewport[0] < 300) {
    unmountAndPrintWarning(root, 'SCREEN WIDTH MUST BE GREATER THAN 300 PIXELS. PLEASE ENLARGE TO VIEW FULL EXPERIENCE.<br><br>屏幕宽度必须大于300像素。请放大查看此经验。<br><br>רוחב המסך חייב להיות גדול מ -300 פיקסלים. אנא התגדל לראות את החוויה הזו.');
  } else if (viewport[1] < 500) {
    unmountAndPrintWarning(root, 'SCREEN HEIGHT MUST BE GREATER THAN 500 PIXELS. PLEASE ENLARGE TO VIEW FULL EXPERIENCE.<br><br>屏幕高度必须大于500像素。请放大查看此经验。<br><br>גובה המסך חייב להיות גדול מ- 500 פיקסלים. אנא התגדל לראות את החוויה הזו.');
  } else {
    if (!_APP_IS_MOUNTED) {
      ReactRender(root);
    }
  }
}

window.addEventListener('resize', () => {
  MaybeReactRender();
});

window.addEventListener("orientationchange", function (event) {
  MaybeReactRender();
});

MaybeReactRender();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
