import React from 'react';
import Links from './Links';
const { motion } = require("framer-motion");

function PageExperience() {
    return (
        <>
            <Links />
        </>
    );
}

export default PageExperience;