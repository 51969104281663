import React from 'react';
import { useEffect, useState, useRef } from "react";
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as Shared from '../Shared';
import { v4 as uuidv4 } from 'uuid';
import { type } from 'jquery';

var models = {};
var renderers = {};
var scenes = {};
var cameras = {};
var animationSpeeds = {};
const defaultAnimationSpeed = 0.026;

function load(ref, src, id, backgroundColor, scale, lights, position) {
    // Instantiate a loader
    var loader = new GLTFLoader();
    // Load a glTF resource
    loader.load(
        // resource URL
        src,
        // called when the resource is loaded
        function (gltf) {
            models[id] = gltf.scene;
            const model = models[id];
            model.scale.set(scale, scale, scale);
            var box = new THREE.Box3().setFromObject(model);
            init(ref, id, backgroundColor, lights, position);
            animate();
        },
        // called while loading is progressing
        function (xhr) {
            if (Shared.DEBUG) {
                console.log(`Loaded ${xhr.loaded} bytes.`);
            }
        },
        // called when loading has errors
        function (error) {
            if (Shared.DEBUG) {
                console.log(`error ${error}`);
            }
        }
    );
};

function init(ref, id, backgroundColor, lights, position) {
    const model = models[id];
    const camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 0.1, 1000);
    cameras[id] = camera;
    camera.position.set(0, 0, 20);

    const scene = new THREE.Scene();
    scenes[id] = scene;
    let x, y, z;
    [x, y, z] = position
    model.position.set(x, y, z);

    var group = new THREE.Group();
    group.add(model);
    scene.add(group);

    // so many lights
    var light0 = new THREE.DirectionalLight(lights[0]['color'], lights[0]['intensity']);
    light0.position.set(0, 1, 0);
    scene.add(light0);

    var light1 = new THREE.DirectionalLight(lights[1]['color'], lights[1]['intensity']);
    light1.position.set(0, -1, 0);
    scene.add(light1);

    var light2 = new THREE.DirectionalLight(lights[2]['color'], lights[2]['intensity']);
    light2.position.set(1, 0, 0);
    scene.add(light2);

    var light3 = new THREE.DirectionalLight(lights[3]['color'], lights[3]['intensity']);
    light3.position.set(0, 0, 1);
    scene.add(light3);

    var light4 = new THREE.DirectionalLight(lights[4]['color'], lights[4]['intensity']);
    light4.position.set(0, 0, -1);
    scene.add(light4);

    var light5 = new THREE.DirectionalLight(lights[5]['color'], lights[5]['intensity']);
    light5.position.set(-1, 0, 0);
    scene.add(light5);

    const renderer = new THREE.WebGLRenderer({
        antialias: true
    });
    renderers[id] = renderer;

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(backgroundColor, 1);
    if (Shared.DEBUG) {
        console.log(`model element ID = ${id}`);
    }
    document.getElementById(id).appendChild(renderer.domElement)

    window.addEventListener('scroll', onMouseWheel, { passive: false });
    window.addEventListener('resize', onWindowResize, { passive: false });

    var animate = function () {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
    };
}

function animate() {
    requestAnimationFrame(animate);
    for (var id in renderers) {
        const renderer = renderers[id];
        renderer.render(scenes[id], cameras[id]);
    }
}

var last_scroll = 0;
function onMouseWheel(event) {
    // https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent/deltaY
    // compatibility isssue on Safari iOS
    // event.preventDefault();
    var scroll_amount = window.scrollY - last_scroll;
    last_scroll = window.scrollY;
    for (var id in models) {
        const model = models[id];
        model.rotation.y += scroll_amount * animationSpeeds[id];
        // model.rotation.y += scroll_amount * 0.026;
    }
}

function onWindowResize() {
    // for (var id in renderers) {
    //     const renderer = renderers[id];
    //     const camera = cameras[id];
    //     cameras.aspect = window.innerWidth / window.innerHeight;
    //     camera.updateProjectionMatrix();
    //     renderer.setSize(window.innerWidth, window.innerHeight);
    // }
}

export default function Model(props) {
    const ref = useRef();
    var modelId = uuidv4();

    var backgroundColor = 'white';
    if (Shared.DEBUG) {
        console.log(`setting model scale`);
    }

    if (Shared.formFactor == Shared.FormFactors.GTE992PX || Shared.formFactor == Shared.FormFactors.GTE992PX_IPADPRO) {
        var scale = .006 / 2098 * Shared.viewportWidth;
    } else if (Shared.formFactor == Shared.FormFactors.LTE991PX) {
        var scale = .004 / 800 * Shared.viewportWidth;
    } else {
        var scale = .008 / 1024 * Shared.viewportWidth;
    }

    var position = [0, 0, 0]
    var lights = [
        {
            'color': 0xffffff,
            'intensity': 1
        },////////

        {
            'color': 0xffffff,
            'intensity': 0.7
        },
        {
            'color': 0xffffff,
            'intensity': 1
        },
        {
            'color': 0xffffff,
            'intensity': 0.7
        },
        {
            'color': 0xffffff,
            'intensity': 1
        },
        {
            'color': 0xffffff,
            'intensity': 0.7
        },
    ];
    animationSpeeds[modelId] = defaultAnimationSpeed;
    if (typeof (props.animationSpeed) !== 'undefined') {
        animationSpeeds[modelId] = props.animationSpeed;
    }
    if (typeof (props.backgroundColor) !== 'undefined') {
        backgroundColor = props.backgroundColor;
    }
    if (typeof (props.scale) !== 'undefined') {
        scale = window.innerWidth > 800 ? props.scale / 2098 * window.innerWidth : props.scale / 800 * window.innerWidth;
    }
    if (typeof (props.lights) != 'undefined') {
        lights = props.lights;
    }
    if (typeof (props.position) != 'undefined') {
        position = props.position;
    }

    return (
        <div
            id={modelId} ref={() => { load(ref, props.src, modelId, backgroundColor, scale, lights, position); }} style={{
                ...props.style
            }}></div >
    )
}