import React from 'react';
import { motion, useMotionTemplate, useTransform, useViewportScroll } from "framer-motion"
import '../App.css'

function getWidth() {
    return '100%';
}

function getMarginLeft() {
    return '42vw';
}

function getHeight() {
    return '1vh';
}

function GradientStatusIndicator(props) {
    const { scrollYProgress } = useViewportScroll();
    const gradientProgress = useTransform(scrollYProgress, [0, 1], [100, 0]);
    const clipPath = useMotionTemplate`inset(0% ${gradientProgress}% 0% 0%)`

    return (
        <motion.div style={{
            backgroundSize: '100% 100%',
            backgroundImage: 'linear-gradient(90deg, #A38BFC, #0BF417, #DEF011, #01E1F8, #01E1F8)',
            width: getWidth(),
            height: getHeight(),
            left: getMarginLeft(),
            clipPath,
            ...props.style
        }} />
    );
}

export default GradientStatusIndicator;