// NOTE: DNDTF stands for "DO NOT DESIGN THE FLOWER" which is a
// creative message used throughout UN7C0RN.
import React from 'react';
import { DNDTFVector } from './DNDTFVector'
import DNDTFUnitVectorFullWidth from './DNDTFVector'
import DNDTFVectorSolid from './DNDTFVectorSolid'
import Video from './Video'
import ProjectMetadata from '../Shared';
import * as Shared from '../Shared';
import { SkipButton } from '../Shared';
import { DADLogo } from '../Shared';
import { FORMLESSLogo } from '../Shared';

function DNDTF(props) {
    const dynamics = props.dynamics;
    var dndtfStyle = { position: 'absolute', minHeight: '100%', left: '0', top: '0' };
    var unicornStyle = { position: 'absolute', top: '117px', left: '31.5vw' };

    if (Shared.DEBUG) {
        console.log(`form factor = ${Shared.formFactor}`);
    }
    var _ = Shared.useFormFactor();
    if (Shared.formFactor == Shared.FormFactors.GTE992PX_IPADPRO) {
        unicornStyle['left'] = '0vw';
        unicornStyle['top'] = '0vw';
        dndtfStyle['top'] = '-25vw';
    } else if (Shared.formFactor == Shared.FormFactors.GTE992PX) {
        dndtfStyle['width'] = '100vw';
        dndtfStyle['height'] = '100vh';
        dndtfStyle['background-color'] = 'black';
    } else if (Shared.formFactor == Shared.FormFactors.LTE991PX) {
        unicornStyle['left'] = '0vw';
        unicornStyle['top'] = '-5vw';
        dndtfStyle['top'] = '-32vw';
    } else if (Shared.formFactor == Shared.FormFactors.LTE767PX) {
        unicornStyle['left'] = '0vw';
        unicornStyle['top'] = '-30vw';
        dndtfStyle['top'] = '-25vw';
    } else if (Shared.formFactor == Shared.FormFactors.LTE479PX) {
        unicornStyle['left'] = '0vw';
        unicornStyle['top'] = '0vw';
    }
    dynamics['scale'].onChange(() => {
        const scale = dynamics['scale'].get();
        if (scale > 105.0 || scale == 0.0) {
            document.getElementById('scaling-flower').style.visibility = "hidden";
        } else if (scale > 1.0) {
            document.getElementById('scaling-flower').style.visibility = "visible";
        }
    });

    if (Shared.formFactor == Shared.FormFactors.GTE992PX_IPADPRO) {
        return (
            <div id='scaling-flower' class='scaling-flower-class' style={{
                backgroundColor: 'black'
            }}>
                <Video src='https://brandontory-http.s3.us-east-2.amazonaws.com/unicorn-dark-3D-20210425-ipadpro.mp4' width='100vw' style={unicornStyle} />
                <DNDTFVectorSolid style={{
                    ...dndtfStyle,
                    position: 'absolute',
                    scale: dynamics['scale']
                }}
                    maxScale={props.maxScale}
                />
                <ProjectMetadata />
                {/* <SkipButton /> */}
                <DADLogo />
                <FORMLESSLogo />
            </div >
        );
    } else if (Shared.formFactor == Shared.FormFactors.GTE992PX) {
        return (
            <div id='scaling-flower' class='scaling-flower-class' style={{
                backgroundColor: 'black'
            }}>
                <div id='svg-clipped-animation' style={dndtfStyle}>
                    <Video src='https://brandontory-http.s3.us-east-2.amazonaws.com/unicorn-dark-3D-20210411-lowfi-web.mp4' width='37vw' style={unicornStyle} />
                </div>
                <DNDTFVectorSolid style={{
                    position: 'absolute',
                    top: '0',
                    scale: dynamics['scale']
                }}
                    maxScale={props.maxScale}
                />
                <ProjectMetadata />
                {/* <SkipButton /> */}
                <DADLogo />
                <FORMLESSLogo />
            </div >
        );
    } else if (Shared.formFactor == Shared.FormFactors.LTE991PX) {
        return (
            <div id='scaling-flower' class='scaling-flower-class' style={{
                backgroundColor: 'black'
            }}>
                <Video src='https://brandontory-http.s3.us-east-2.amazonaws.com/unicorn-dark-3D-20210425-tablet.mp4' width='100vw' style={unicornStyle} />
                <DNDTFVectorSolid style={{
                    position: 'absolute',
                    ...dndtfStyle,
                    scale: dynamics['scale']
                }}
                    maxScale={props.maxScale}
                />
                <ProjectMetadata />
                {/* <SkipButton /> */}
                <DADLogo />
                <FORMLESSLogo />
            </div >
        );
    } else if (Shared.formFactor == Shared.FormFactors.LTE767PX) {
        return (
            <div id='scaling-flower' class='scaling-flower-class' style={{
                backgroundColor: 'black'
            }}>
                <Video src='https://brandontory-http.s3.us-east-2.amazonaws.com/unicorn-dark-3D-20210409-mobile.mp4' width='100vw' style={unicornStyle} />
                <DNDTFVectorSolid style={{
                    ...dndtfStyle,
                    position: 'absolute',
                    scale: dynamics['scale']
                }}
                    maxScale={props.maxScale}
                />
                <ProjectMetadata />
                {/* <SkipButton /> */}
                <DADLogo />
                <FORMLESSLogo />
            </div >
        );
    } else if (Shared.formFactor == Shared.FormFactors.LTE479PX) {
        return (
            <div id='scaling-flower' class='scaling-flower-class' style={{
                backgroundColor: 'black'
            }}>
                <Video src='https://brandontory-http.s3.us-east-2.amazonaws.com/unicorn-dark-3D-20210409-mobile.mp4' width='100vw' style={unicornStyle} />
                <DNDTFVectorSolid style={{
                    ...dndtfStyle,
                    position: 'absolute',
                    scale: dynamics['scale']
                }}
                    maxScale={props.maxScale}
                />
                <ProjectMetadata />
                {/* <SkipButton /> */}
                <DADLogo />
                <FORMLESSLogo />
            </div >
        );
    }
}

export default DNDTF;