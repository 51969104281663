import React from 'react';
import { useLayoutEffect, useState } from 'react';

export const brandOffBlack = '#090909';
export const brandDarkBlack = '#000000';
export const brandGrey = '#F7F9F9';
export const brandPurple = '#A38BFC'
// export const brandH1Font = 'Helvetica Neue LT Std';
export const brandH1Font = 'VCR OSD Mono';
export const brandH2Font = 'Zuume SemiBold Italic';
// export const brandH3Font = 'Neue Helvetica Georgian 85 Heavy';
// export const brandCopyFont = 'Neue Helvetica Pro 65 Medium';
// export const brandCodeFont = 'Neue Helvetica Pro 65 Medium';
export const brandH2FontLetterSpacing = '.2rem';

export const FormFactors = Object.freeze({ "GTE992PX": 0, "LTE991PX": 1, "LTE767PX": 2, "LTE479PX": 3, "GTE992PX_IPADPRO": 4 });

function updateMetadataTimestamp() {
    const timestamp = new Date();
    const element = document.getElementById('metadataTimestamp');
    if (element) {
        element.innerText = `${timestamp.getHours()}:${timestamp.getMinutes()}:${timestamp.getSeconds()}:${('00' + timestamp.getMilliseconds()).slice(-3)}`;
    }
    setTimeout(updateMetadataTimestamp, 50);
}

export function DADLogo(props) {
    return (
        <img src="dad-logo-black.png" style={{
            // position: 'relative',
            // display: 'block',
            width: '20%',
            right: '10px',
            top: '20px',
            paddingBottom: '10px',
            position: 'fixed',
            zIndex: '100'
        }} />
    );
}

export function FORMLESSLogo(props) {
    return (
        <img src="FORMLESS LOGO 2020_Final_FORMLESS_LOGO_BLACK.png" style={{
            // position: 'relative',
            // display: 'block',
            width: '30%',
            // left: '100px',
            bottom: '0px',
            paddingBottom: '10px',
            position: 'fixed',
            zIndex: '100'
        }} />
    );
}



export function SkipButton(props) {
    const cjktStyle = {
        fontSize: '1.0em',
        fontFamily: brandH1Font,
        color: 'black',
        textAlign: 'center',
        paddingBottom: '10px'
    }

    return (
        <div style={{
            ...cjktStyle,
            position: 'fixed',
            bottom: '0px',
            width: '100%',
            zIndex: '100',
        }}>
            <a onClick={() => {
                window.gtag('event', 'home_skip3Dexperience_click', {});
            }} style={{ fontSize: '1.0em', "color": "black", "textDecoration": "underline" }} href="/stream">SKIP</a>
        </div>);
}

function ProjectMetadata(props) {
    const cjktStyle = {
        position: 'absolute',
        top: '0px',
        width: '50vw',
        fontSize: '0.5vw',
        marginLeft: '1vw',
        fontFamily: brandH1Font,
        color: 'black',
    }

    return (
        <div>
            <h2 style={{ ...cjktStyle, fontSize: '1em' }}>
                BRANDON TORY<br></br>
    UN7C0RN<br></br>
    SCROLL DOWN FOR MORE<br></br>
            </h2><br></br>
        </div>
    );
}

export function getViewport() {

    var viewPortWidth;
    var viewPortHeight;

    // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
    if (typeof window.innerWidth != 'undefined') {
        viewPortWidth = window.innerWidth;
        viewPortHeight = window.innerHeight;
    }

    // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
    else if (typeof document.documentElement != 'undefined'
        && typeof document.documentElement.clientWidth !=
        'undefined' && document.documentElement.clientWidth != 0) {
        viewPortWidth = document.documentElement.clientWidth;
        viewPortHeight = document.documentElement.clientHeight;
    }

    // older versions of IE
    else {
        viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
        viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
    }
    return [viewPortWidth, viewPortHeight];
}

export function getFormFactor(viewport) {
    const viewportWidth = viewport[0];
    const viewportHeight = viewport[1];

    if (viewportWidth >= 992) {
        if (viewportHeight > viewportWidth) {
            return FormFactors.GTE992PX_IPADPRO;
        } else {
            return FormFactors.GTE992PX;
        }
    } else if (viewportWidth > 767) {
        return FormFactors.LTE991PX;
    } else if (viewportWidth > 479) {
        return FormFactors.LTE767PX;
    } else {
        return FormFactors.LTE479PX;
    }
}

// https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export function useFormFactor() {
    var size = useWindowSize();
    return getFormFactor(size[0]);
}

// https://stackoverflow.com/questions/1766861/find-the-exact-height-and-width-of-the-viewport-in-a-cross-browser-way-no-proto
export var viewport = getViewport();
export var formFactor = getFormFactor(viewport);
export var viewportWidth = viewport[0];
export var viewportHeight = viewport[1];
export const DEBUG = false;

window.addEventListener('resize', () => {
    viewport = getViewport();
    formFactor = getFormFactor(viewport);
    if (DEBUG) {
        console.log(`Updated viewport metrics.`);
    }

    // if (viewport[0] < 900) {
    //     var root = document.getElementById('root')
    //     root.innerHTML = "<h1>nope</h1>"
    // } else {

    // }
});

export default ProjectMetadata;