import React from 'react';
import './App.css'
import DynamicScrollExperience from './components/DynamicScrollExperience';
import PageExperience from './components/PageExperience';
import Stream from './components/Stream';
import GradientStatusIndicator from './components/GradientStatusIndicator';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import EasterEggs from './components/EasterEggs';
import * as QueryString from 'query-string'
import QrReader from 'react-qr-reader'

function App() {
  return (
    <Router>
      <Route path="/" exact component={(props) => {
        return (
          <div style={{ height: '100%' }}>
            <div style={{
              position: 'fixed',
              top: '0px',
              width: '100%',
              zIndex: '100',
            }}>
              <GradientStatusIndicator />
            </div>
            <DynamicScrollExperience />
            <PageExperience />
          </div>
        );
      }} />
      <Route path="/ar" exact component={(props) => {
        const params = QueryString.parse(props.location.search)
        window.location.href = `experiences/${params.experience_id}/model.html`;
        return (
          <div>
            <h1>UN7C0RN AR</h1>
            <p>Loading experience ID <b>{params.experience_id}</b></p>
          </div>
        )
      }} />
      <Route path="/stream" exact component={(props) => {
        window.location.href = "https://hypeddit.com/link/m6pwyh"
        return null;
      }} />
      <Route path="/eastereggs" exact component={(props) => {
        return (
          <div style={{
            backgroundColor: "#090909"
          }}>
            <div style={{
              position: 'fixed',
              top: '0px',
              width: '100%',
              zIndex: '100',
            }}>
              <GradientStatusIndicator />
            </div>
            <EasterEggs />
          </div>
        );
      }} />
      <Route path="/NFT" exact component={(props) => {
        window.location.href = 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/83272843560816296043766541164526720139940055227036661567751036433940348928001';
        return (
          <div style={{ width: '100vw', height: '100vh', backgroundColor: "#090909" }}>
            <img src='FRONT_UN7C0RNAsset 6@3x.png' style={{
              position: 'relative',
              display: 'block',
              width: '95%',
              margin: 'auto',
              opacity: 0.037
            }} />
          </div>
        );
      }} />
      <Route path="/instructions" component={(props) => {
        return (
          <div>
            <center>
              <h1>Scan QR code and hover your device.</h1>
              <div>
                <QrReader
                  delay={500}
                  onError={(err) => {
                    console.log(err);
                  }}
                  onScan={(scan) => {
                    if (scan != null) {
                      window.location.href = scan;
                    }
                  }}
                  showViewFinder={false}
                  style={{ width: '70vw', height: '70vw' }}
                />
              </div>
            </center>
            <p style={{ marginLeft: '5vw', marginRight: '5vw' }}>Not working? Try pointing the camera closer and holding still.</p>
          </div>
        )
      }} />
    </Router>
  );
}

export default App;
