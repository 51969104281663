import React from 'react';
import * as Shared from '../Shared';
const { motion } = require("framer-motion");

function getWidth() {
    return '100vw';
}

function getHeight() {
    return '100vh';
}

function SongLyrics(props) {
    const dynamics = props.dynamics;

    return (
        <motion.div style={{
            position: 'absolute',
            opacity: dynamics['opacity'],
            scale: dynamics['scale'],
            width: getWidth(),
            height: getHeight(),
            ...props.style
        }}>
            <motion.div style={{
                position: 'absolute',
                height: 'auto',
                width: '85vw',
                top: '20vh',
                left: '7vw',
                right: '7vw',
                opacity: dynamics['opacity'],
                backgroundColor: 'white',
                scale: dynamics['scale'],
                ...props.style
            }}>
                <center>
                    <h2 style={{
                        fontFamily: "VCR OSD Mono",
                        fontSize: '50px',
                        letterSpacing: Shared.brandH2FontLetterSpacing,
                        top: '100px',
                        color: 'black'
                    }}>"""<br></br>
                    WE<br></br>
                    ARE<br></br>
                    UNICORNS<br></br>
                    """<br></br>
                    </h2>
                </center>
            </motion.div>
        </motion.div>)
}

export default SongLyrics;