import React from 'react';
import Unicorn3D from './Unicorn3D';
import VideoExperience from './VideoExperience';
import SongLyrics from './SongLyrics';
import DNDTF from './DNDTF';
import useDynamicsOnScroll from './DynamicsOnScroll';

// Number of seconds for the entire experience to complete
var PARENT_PERIOD = 1.0
var SCENE_TIME = 0.25

function DynamicScrollExperience() {
    const unicorn3DDynamics = useDynamicsOnScroll({
        'preSetup': SCENE_TIME - (SCENE_TIME / 10),
        'setup': 0,
        'hold': 1 * SCENE_TIME,
        'preFinish': .1 * SCENE_TIME,
        'parentPeriod': PARENT_PERIOD,
        'startScale': 1.0,
        'holdScale': 1.0,
        'stopScale': 1.0,
        'startOpacity': 0.0,
        'holdOpacity': 1.0,
        'stopOpacity': 0.0
    });

    const videoExperienceDynamics = useDynamicsOnScroll({
        'preSetup': 1.5 * SCENE_TIME,
        'setup': 0.5 * SCENE_TIME,
        'hold': SCENE_TIME / 2,
        'preFinish': SCENE_TIME / 2,
        'parentPeriod': PARENT_PERIOD,
        'startScale': 1.1,
        'holdScale': 1.1,
        'stopScale': 2.0,
        'startOpacity': 0.0,
        'holdOpacity': 1.0,
        'stopOpacity': 1.0
    });

    const songLyricDynamics = useDynamicsOnScroll({
        'preSetup': 2.5 * SCENE_TIME,
        'setup': 0,
        'hold': SCENE_TIME / 2,
        'preFinish': SCENE_TIME * 3,
        'parentPeriod': PARENT_PERIOD,
        'startScale': 0.7,
        'holdScale': 1.0,
        'stopScale': 3.0,
        'startOpacity': 0.0,
        'holdOpacity': 1.0,
        'stopOpacity': 1.0
    });

    const dndtfDynamics = useDynamicsOnScroll({
        'preSetup': 0.0,
        'setup': 0.0, // (0.1 * SCENE_TIME) / 2,
        'hold': 0.0, // 0 * SCENE_TIME / 2,
        'preFinish': 1.0 * SCENE_TIME,
        'parentPeriod': PARENT_PERIOD,
        // very strange issue here where if this is 1.0
        // the scale never gets restored to 1.
        'startScale': 0.99999999,
        'holdScale': 1.0,
        'stopScale': 110.0,
        'startOpacity': 1.0,
        'holdOpacity': 1.0,
        'stopOpacity': 1.0
    });

    return (
        <div style={{
            height: '400vh',
        }}>
            <div style={{
                position: 'fixed'
            }}>
                <SongLyrics dynamics={songLyricDynamics} />
                <VideoExperience dynamics={videoExperienceDynamics} />
                <Unicorn3D dynamics={unicorn3DDynamics} />
                <DNDTF dynamics={dndtfDynamics} maxScale={110.0} />
            </div>
        </div>
    );
}

export default DynamicScrollExperience;