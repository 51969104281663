import React from 'react';
import * as Shared from '../Shared';
const { motion } = require("framer-motion");

function updateMetadataTimestamp() {
    const timestamp = new Date();
    const element = document.getElementById('streamMetadataTimestamp');
    if (element) {
        element.innerText = `${timestamp.getHours()}:${timestamp.getMinutes()}:${timestamp.getSeconds()}:${('00' + timestamp.getMilliseconds()).slice(-3)}`;
    }
    setTimeout(updateMetadataTimestamp, 50);
}

function Stream(props) {
    var fontSize = '1em';
    var youtubeVideoHeight = '250px';
    if (Shared.formFactor == Shared.FormFactors.GTE992PX) {
        fontSize = '0.2em';
        youtubeVideoHeight = '500px';
    } else if (Shared.formFactor == Shared.FormFactors.GTE992PX_IPADPRO) {
        fontSize = '0.3em';
        youtubeVideoHeight = '500px';
    } else if (Shared.formFactor == Shared.FormFactors.LTE991PX) {
        fontSize = '0.4em';
    } else if (Shared.formFactor == Shared.FormFactors.LTE767PX) {
        fontSize = '0.5em';
    }
    else {
        fontSize = '0.9em';
    }

    const cjktStyle = {
        position: 'absolute',
        top: '0px',
        width: '75vw',
        fontSize: '1em',
        marginLeft: '1vw',
        fontFamily: Shared.brandH1Font,
        color: 'black',
    }
    var additionalInfoStyle = {
        marginTop: '10px',
        fontSize: '1.0em',
        marginLeft: '2vw',
        marginRight: '2vw',
        fontFamily: Shared.brandH1Font,
        color: 'black',
    }
    const tableStyle = {
        color: 'black',
        fontFamily: Shared.brandH2Font,
        fontSize: "9vw",
        width: '100%',
        marginTop: '120px'
    }
    const tableDataStyle = {
        border: '1px solid black',
        borderLeft: 'none',
        borderRight: 'none',
        width: '100%',
        paddingTop: "1vh",
        paddingBottom: "1vh",
        paddingLeft: '2vw',
        width: '30%',
        textAlign: 'center'
    }
    const tableDataRightBorderStyle = {
        ...tableDataStyle,
        borderRight: '1px solid black'
    }

    if (Shared.formFactor == Shared.FormFactors.LTE479PX) {
        additionalInfoStyle['fontSize'] = '1.0em'
    }

    return (
        <div>
            <h2 style={{ ...cjktStyle, fontSize: '1em' }}>
                artist: brandon tory<br></br>
        album: "UN7C0RN"<br></br>
                song: "STREETS OF GOLD"<br></br>
                <div id="streamMetadataTimestamp" ref={() => {
                    updateMetadataTimestamp()
                }} style={{
                    fontFamily: Shared.brandH1Font,
                    color: 'black', fontSize: '1em'
                }}>
                </div>
                [{Shared.getViewport()[0]},{Shared.getViewport()[1]}]
            </h2><br></br>
            <table style={tableStyle}>
                <tr>
                    <td><iframe width="100%" height={youtubeVideoHeight} src="https://www.youtube.com/embed/lFy3ca9EM6M?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe></td>
                    {/* <td><iframe width="100%" height={youtubeVideoHeight} src="https://www.youtube.com/embed/1aVf82CIP_U?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe></td> */}
                </tr>
                <tr>
                    <td style={{ ...tableDataStyle, marginTop: '50px' }}>
                        {/* <a onClick={() => {
                            window.gtag('event', 'stream_community_click', {});
                        }} className={'black-link'} href="https://my.community.com/brandontory">JOIN</a> */}

                        <div dangerouslySetInnerHTML={{
                            __html: `
<!-- Begin Mailchimp Signup Form -->
<script type='text/javascript'>
function mailchimpSubscribeEvent() {
    // 
    alert('hello');
}
</script>
<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
<style type="text/css">
	#mc_embed_signup{background:#fff; clear:left; font-family: Zuume SemiBold Italic; font-size: ${fontSize}; width:100%;}
	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="https://formless.us1.list-manage.com/subscribe/post?u=0a98369671713a4d56d4f2649&amp;id=a34d6828c0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
	Enter your email address to receive the album presave link
<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="mc-field-group">
	<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_0a98369671713a4d56d4f2649_a34d6828c0" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" onclick="window.gtag('event', 'stream_mailchimp_subscribe_click', {});"></div>
    </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup--></div>`
                        }}></div>

                    </td>
                </tr>
            </table>
            <table style={tableStyle}>
                <tr>
                    <td style={tableDataRightBorderStyle}><a onClick={() => {
                        window.gtag('event', 'stream_youtube_click', {});
                    }} className={'black-link'} href="https://youtube.com/brandontory">YOUTUBE</a></td>
                    <td style={tableDataStyle}><a onClick={() => {
                        window.gtag('event', 'stream_soundcloud_click', {});
                    }} className={'black-link'} href="https://soundcloud.com/brandontory/streets-of-gold-un7c0rn">SOUNDCLOUD</a></td>
                </tr>
            </table>
            <p style={{ ...additionalInfoStyle }}>
                Choose your preferred music service.
                        </p>
            <p style={{ ...additionalInfoStyle, fontSize: '0.8em', marginTop: '20px' }}>
                FULL 3D EXPERIENCE: <a onClick={() => {
                    window.gtag('event', 'stream_fullexperience_click', {});
                }} style={{
                    "color": "black", "textDecoration": "underline"
                }} href="/">un7c0rn.com</a></p>
        </div >
    );
}

export default Stream;