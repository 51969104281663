import React from 'react';
import { DNDTFVector } from './DNDTFVector'
import DNDTFUnitVectorFullWidth from './DNDTFVector'
import DNDTFVectorSolid from './DNDTFVectorSolid'
import Video from './Video'
import ProjectMetadata from '../Shared';
import * as Shared from '../Shared';
import GradientStatusIndicator from './GradientStatusIndicator';

function updateMetadataTimestamp() {
    const timestamp = new Date();
    const element = document.getElementById('streamMetadataTimestamp');
    if (element) {
        element.innerText = `${timestamp.getHours()}:${timestamp.getMinutes()}:${timestamp.getSeconds()}:${('00' + timestamp.getMilliseconds()).slice(-3)}`;
    }
    setTimeout(updateMetadataTimestamp, 50);
}

function EasterEggs(props) {

    var additionalInfoStyle = {
        marginTop: '10px',
        fontSize: '1.0em',
        marginLeft: '2vw',
        marginRight: '2vw',
        fontFamily: Shared.brandH1Font,
        color: 'white',
    }

    const cjktStyle = {
        top: '0px',
        width: '75vw',
        fontSize: '1em',
        marginLeft: '1vw',
        fontFamily: Shared.brandH1Font,
        color: 'white',
    }
    const tableStyle = {
        color: 'white',
        fontFamily: Shared.brandH2Font,
        fontSize: "9vw",
        width: '100%',
        marginTop: '120px'
    }
    const tableDataStyle = {
        border: '1px solid black',
        borderLeft: 'none',
        borderRight: 'none',
        width: '100%',
        paddingTop: "1vh",
        paddingBottom: "1vh",
        paddingLeft: '2vw',
        width: '30%',
        textAlign: 'center'
    }
    const tableDataRightBorderStyle = {
        ...tableDataStyle,
        borderRight: '1px solid black'
    }
    if (Shared.formFactor == Shared.FormFactors.LTE479PX) {
        additionalInfoStyle['fontSize'] = '1.0em'
    }

    var width = '62vw';
    if (Shared.formFactor != Shared.FormFactors.LTE767PX)
    {
        width = '300px';
    }

    return (
        <div style={{ "backgroundColor": "#000", height: '100vh', width: '100%' }}>

            {/* <h1 style={{ ...tableStyle, marginLeft: '20px', marginRight: 'auto', width: '50%' }}>EASTER EGGS</h1> */}

            {/* <h2 style={{
                ...cjktStyle, fontSize: '1em',
                position: 'static',
                marginTop: '50px'
            }}>
            </h2> */}
            <iframe onLoad={() => {
                window.gtag('event', 'easteregg_iframe_load', {});
            }} style={{ display: 'block', marginTop: '0', marginLeft: 'auto', marginRight: 'auto', border: 0, width: width, height: '442px' }} src="https://bandcamp.com/EmbeddedPlayer/track=3537428765/size=large/bgcol=ffffff/linkcol=333333/tracklist=false/transparent=true/" seamless><a href="https://true508.bandcamp.com/track/money-cant-buy-love-b">Money Can&#39;t Buy Love ($ØB♡) by brandontory</a></iframe>
        </div >
    );
}

export default EasterEggs;