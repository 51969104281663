import React from 'react';

function getWidth() {
    return '100vw';
}

export default function Video(props) {
    var width = getWidth();
    if (typeof (props.width) !== 'undefined') {
        width = props.width;
    }
    return (
        <video style={{ marginTop: '0', paddingTop: '0', width, ...props.style }}
            loop
            autoPlay
            muted
            playsInline src={props.src} type='video/mp4' />
    );
}